<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Πιστοποιητικό Επαγγελματικής Ικανότητας (Π.Ε.Ι)
            </h2>

            <p class="subtitle-1">
              Πιστοποιητικό Επαγγελματικής Ικανότητας ( Π.Ε.Ι.) είναι αυτό το οποίο πιστοποιεί ότι ο συγκεκριμένος οδηγός έχει την απαιτούμενη αρχική επιμόρφωση ή περιοδική κατάρτιση,σύμφωνα με όσα ορίζονται στο διάταγμα και επομένως επιτρέπεται να οδηγεί συγκεκριμένης ή συγκεκριμένων κατηγοριών ή υποκατηγοριών οδικά οχήματα μεταφοράς επιβατών ή εμπορευμάτων, εφόσον κατέχει και ισχύουσα άδεια οδήγησης της συγκεκριμένης ή των συγκεκριμένων κατηγοριών ή υποκατηγοριών.
            </p>
            <br>
            <p class="display-1">
              Το Π.Ε.Ι. διακρίνεται σε 2 κατηγορίες :
            </p><p class="subtitle-1">
              <ul>
                <li>Π.Ε.Ι. Μεταφοράς Εμπορευμάτων (Φορτηγά) όπου οι οδηγοί όταν οδηγούν οχήματα των υποκατηγοριών Γ1,Γ1+Ε ή των κατηγοριών Γ,Γ+Ε είναι υποχρεωμένοι, πέραν της απαιτούμενης άδειας οδήγησης να κατέχουν και ΠΕΙ μεταφοράς</li>
                <li>Π.Ε.Ι. Μεταφοράς Επιβατών (Λεωφορεία) όπου οι οδηγοί όταν οδηγούν οχήματα των υποκατηγοριών Δ1,Δ1+Ε ή των κατηγοριών Δ,Δ+Ε είναι υποχρεωμένοι,πέραν της απαιτούμενης άδειας οδήγησης να κατέχουν και ΠΕΙ μεταφοράς Σύμφωνα με το Π.Δ.74 και με την Ευρωπαϊκή Οδηγία για την Δια Βίου Εκπαίδευση του Επαγγελματία ανάλογα με το αν πρόκειται για νέο οδηγό ( για κάποιον δηλαδή που αποκτά σήμερα το δίπλωμα οδήγησης της αντίστοιχης κατηγορίας).</li>
                <br>
              </ul>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/epagelmatiko.jpg" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <h2
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
          class="mb-4"
        >
          Για εν ενεργεία οδηγό το Π.Ε.Ι. διακρίνεται στα:
        </h2>
        <v-row>
          <v-col
            v-for="(highlight, i) in highlights"
            :key="i"
            cols="12"
            md="6"
          >
            <v-card-title v-text="highlight" />
            <v-text
              v-if="highlight=='Π.Ε.Ι. Αρχικής Επιμόρφωσης'"
            >
              Αποκτάται ύστερα απο επιτυχία στις επιπλέον ( του διπλώματος οδήγησης) Θεωρητικές και Πρακτικές Εξετάσεις και υποχρεούνται να το κατέχουν όσοι απόκτησαν ή θα αποκτήσουν:
              <ul>
                <ul>
                  <li>όσοι έχουν εκδώσει την Γ,Γ+Ε από τις 9 Σεπτεμβρίου 2009 και μετα.</li>
                  <li>όσοι έχουν εκδώσει την Δ, εως τις 9 Σεπτεμβρίου 2008 και μετα</li>
                </ul>
                <li>ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</li>
                <ul>
                  <li>Α.Φ.Μ.</li>
                  <li>Φωτοτυπία διπλώματος</li>
                  <li>2 φωτογραφίες διαβατηρίου</li>
                  <li>Φωτοτυπία ταυτότητας ή άδεια διαμονής (για αλλοδαπούς)</li>
                </ul>
                <li>ΠΑΡΑΒΟΛΑ</li>
                <ul>
                  <li>Παράβολο 30€ Άδεια Οδήγησης (κωδ. παραβόλου: 0028) e-Παράβολο</li>
                  <li>Παράβολο 2Χ20€ Εξετάσεις Θεωρίας Π.Ε.Ι. (κωδ. παραβόλου: 0026) Εξετάσεις Πρακτικής Π.Ε.Ι. (κωδ. παραβόλου: 0027) <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a></li>
                </ul>
                <br>
              </ul>
            </v-text>
            <v-text v-if="highlight=='Π.Ε.Ι. Περιοδικής Κατάρτισης'">
              Αποκτάται ύστερα από επιτυχή παρακολούθηση 35 ωρών Θεωρητικής Εκπαίδευσης ( χωρίς Εξετάσεις ) και υποχρεούνται να το κατέχουν οι οδηγοί:
              <ul>
                <ul>
                  <li>όσοι έχουν εκδώσει την Γ,Γ+Ε εως τις 9 Σεπτεμβρίου 2009</li>
                  <li>όσοι έχουν εκδώσει την Δ, εως τις 9 Σεπτεμβρίου 2008 και μετά</li>
                </ul>
                <li>ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ</li>
                <ul>
                  <li>Α.Φ.Μ.</li>
                  <li>Φωτοτυπία διπλώματος</li>
                  <li>2 φωτογραφίες διαβατηρίου</li>
                  <li>Φωτοτυπία ταυτότητας ή άδεια διαμονής (για αλλοδαπούς)</li>
                </ul>
                <li>ΠΑΡΑΒΟΛΑ</li>
                <ul>
                  <li>Παράβολο 30€ Άδεια Οδήγησης (κωδ. παραβόλου: 0028) <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a></li>
                </ul>
              </ul>
            </v-text>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            cols="6"
            md="6"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      highlights: [
        'Π.Ε.Ι. Αρχικής Επιμόρφωσης',
        'Π.Ε.Ι. Περιοδικής Κατάρτισης'
      ],
      experiences: [
        ['mdi-steering', '8 ώρες', 'Πρακτικα μαθηματα'],
        ['mdi-book-open-variant', '0 ώρες', 'θεωρητικα μαθηματα']
      ],
      pdfs: [
        ['Εκτύπωση Αίτησης', 'static/εκτύπωση-αίτησης.pdf'],
        ['Χόρηγηση Π.Ε.Ι. Με Περιοδική Κατάρτηση', 'static/χορήγηση-πει-με-περιοδική-κατάρτιση-ΜΤΑΟ18.pdf'],
        ['Αίτηση Χορήγησης ΠΕΙ Αρχικής Επιμόρφωσης', 'static/Αίτηση-Χορήγησης-ΠΕΙ-Αρχικής-Επιμόρφωσης-Μ-ΤΑΟ06.pdf'],
        ['e-Παράβολο', 'https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
